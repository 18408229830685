import React, { useEffect } from "react";
import styles from "./Promo.module.css";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import AppLoader from "../../utils/AppLoader/AppLoader";
import { connect } from "react-redux";
import { getPromoCodes } from "../../../containers/endUser/actions";
import DataTable from "react-data-table-component";
import { withRouter } from "react-router-dom";
import TablePagination from "@material-ui/core/TablePagination";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Modal from "./Modal/Modal";
import moment from "moment";
import ConfirmAlert from "../../utils/ConfirmAlert/ConfirmAlert";
import { IconButton, Tooltip } from "@material-ui/core";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { showAlert } from "../../../containers/app/actions";

const Promo = (props) => {
    const [searchVal, setSearchVal] = React.useState("");
    const [dropDown, setDropDown] = React.useState("All");
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const token = localStorage.getItem("token");

    const downloadCsv = () => {
        axios({
            method: "get",
            url: `/dashboard/promoCode/getPromoCodes?page=${page + 1}&limit=${rowsPerPage}&search=${
                searchVal ?? ""
            }&startDate=${startDate ?? ""}&endDate=${endDate ?? ""}&csv=true`,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "PromoCode.csv"); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                console.log(err);
            });
    };
    React.useEffect(() => {
        props.getPromoCodes(rowsPerPage, page + 1, searchVal, "", "", "");
    }, []);
    const handleChange = (event, newPage) => {
        props.getPromoCodes(
            rowsPerPage,
            newPage + 1,
            searchVal,
            startDate,
            endDate,
            dropDown === "All" ? "" : dropDown,
        );
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        props.getPromoCodes(
            event.target.value,
            page + 1,
            searchVal,
            startDate,
            endDate,
            dropDown === "All" ? "" : dropDown,
        );
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleSearchChange = (e) => {
        setPage(0);
        props.getPromoCodes(rowsPerPage, 1, e.target.value, startDate, endDate);
    };

    const handleDateFilter = (start, end) => {
        props.getPromoCodes(rowsPerPage, 1, searchVal, start ?? startDate, end ?? endDate);
        setPage(0);
    };

    const deletePromoCode = (_id) => {
        axios({
            method: "put",
            url: "/dashboard/promoCode/deletePromo",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                _id,
            },
        })
            .then((res) => {
                if (res) {
                    props.showAlert("PROMO CODE DELETED SUCCESSFULLY");
                    props.getPromoCodes(rowsPerPage, page + 1, searchVal, "", "", "");
                }
            })
            .catch((err) => {
                if (err && err.response) {
                    setLoading(false);
                    props.showAlert("Something Went Wrong");
                }
            });
    };

    let rowData = [];
    let isLoading = !props.promos;
    let showData = !isLoading;

    !isLoading &&
        props.promos.forEach((item, index) => {
            rowData.push({
                sl_no: index + 1,
                ...item,
                redemptDate: item.redemptionDate ? moment(item.redemptionDate).format("DD/MM/YYYY, hh:mm") : "",
                autoRenwal: item.isAutoRenwal ? "Yes" : "No",
                StartDate: moment(item.startDate).format("DD/MM/YYYY, hh:mm"),
                EndDate: moment(item.endDate).format("DD/MM/YYYY, hh:mm"),
                subscriptionEndDate: item?.subscriptionEndDate
                    ? moment(item.subscriptionEndDate).format("DD/MM/YYYY, hh:mm")
                    : "",
                // EndDate: moment(item.endDate).format("DD/MM/YYYY, hh:mm"),
                action: (
                    <>
                        {" "}
                        <ConfirmAlert
                            msg={`Are you sure you want to delete?`}
                            onClickEvent={() => deletePromoCode(item._id)}
                        >
                            <Tooltip title="Delete">
                                <IconButton>
                                    <DeleteOutline style={{ color: "red" }} />
                                </IconButton>
                            </Tooltip>
                        </ConfirmAlert>
                    </>
                ),
            });
        });

    return (
        <div className={styles.container}>
            <Modal open={open} onClose={() => setOpen(false)} />
            <div className={styles.topHeader}>
                <div>
                    {" "}
                    <h3>PromoCode</h3>
                    <p>Create and view status of promo codes for companies</p>
                </div>
                <div>
                    <h4>Redemption Date</h4>
                    <div className={styles.dateFilter}>
                        <TextField
                            type="date"
                            label="Start Date"
                            variant="outlined"
                            onChange={(e) => {
                                handleDateFilter(e.target.value, null);
                                setStartDate(e.target.value);
                            }}
                            InputLabelProps={{ shrink: true, required: true }}
                            className={styles.textField}
                        />
                        <TextField
                            type="date"
                            label="End Date"
                            variant="outlined"
                            onChange={(e) => {
                                handleDateFilter(null, e.target.value);
                                setEndDate(e.target.value);
                            }}
                            InputLabelProps={{ shrink: true, required: true }}
                            className={styles.textField}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.header}>
                <div className={styles.leftHeader}>&nbsp;</div>

                <div className={styles.rightHeader}>
                    <svg
                        onClick={() => setOpen(true)}
                        width="29"
                        height="29"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.6031 21.375C14.901 21.375 15.1474 21.2776 15.3422 21.0828C15.537 20.888 15.6344 20.6417 15.6344 20.3438V15.6688H20.3781C20.6531 15.6688 20.888 15.5714 21.0828 15.3766C21.2776 15.1818 21.375 14.9354 21.375 14.6375C21.375 14.3396 21.2776 14.0932 21.0828 13.8984C20.888 13.7036 20.6417 13.6063 20.3438 13.6063H15.6344V8.62187C15.6344 8.34687 15.537 8.11198 15.3422 7.91719C15.1474 7.7224 14.901 7.625 14.6031 7.625C14.3052 7.625 14.0589 7.7224 13.8641 7.91719C13.6693 8.11198 13.5719 8.35833 13.5719 8.65625V13.6063H8.62187C8.34687 13.6063 8.11198 13.7036 7.91719 13.8984C7.7224 14.0932 7.625 14.3396 7.625 14.6375C7.625 14.9354 7.7224 15.1818 7.91719 15.3766C8.11198 15.5714 8.35833 15.6688 8.65625 15.6688H13.5719V20.3781C13.5719 20.6531 13.6693 20.888 13.8641 21.0828C14.0589 21.2776 14.3052 21.375 14.6031 21.375ZM14.5 28.25C12.5521 28.25 10.7417 27.9005 9.06875 27.2016C7.39583 26.5026 5.94063 25.5344 4.70312 24.2969C3.46563 23.0594 2.4974 21.6042 1.79844 19.9312C1.09948 18.2583 0.75 16.4479 0.75 14.5C0.75 12.575 1.09948 10.776 1.79844 9.10312C2.4974 7.43021 3.46563 5.975 4.70312 4.7375C5.94063 3.5 7.39583 2.52604 9.06875 1.81563C10.7417 1.10521 12.5521 0.75 14.5 0.75C16.425 0.75 18.224 1.10521 19.8969 1.81563C21.5698 2.52604 23.025 3.5 24.2625 4.7375C25.5 5.975 26.474 7.43021 27.1844 9.10312C27.8948 10.776 28.25 12.575 28.25 14.5C28.25 16.4479 27.8948 18.2583 27.1844 19.9312C26.474 21.6042 25.5 23.0594 24.2625 24.2969C23.025 25.5344 21.5698 26.5026 19.8969 27.2016C18.224 27.9005 16.425 28.25 14.5 28.25Z"
                            fill="#2D4141"
                        />
                    </svg>
                    <TextField
                        placeholder="Search Here"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        className={styles.search}
                        value={searchVal}
                        onChange={(e) => {
                            setSearchVal(e.target.value);
                            handleSearchChange(e);
                        }}
                    />
                    <button
                        onClick={() => {
                            downloadCsv();
                        }}
                    >
                        Download
                    </button>
                </div>
            </div>
            {isLoading && <AppLoader />}

            {showData && (
                <React.Fragment>
                    {showData && (
                        <DataTable
                            noHeader={true}
                            fixedHeader={true}
                            pagination={false}
                            fixedHeaderScrollHeight={"calc(100vh - 320px)"}
                            columns={[
                                {
                                    name: "Code",
                                    selector: "code",
                                    sortable: true,
                                    minWidth: "150px",
                                },
                                {
                                    name: "Entitlement Type",
                                    selector: "entitlementType",
                                    sortable: true,
                                    minWidth: "150px",
                                },
                                { name: "Company", selector: "companyName", sortable: true, minWidth: "140px" },
                                { name: "StartDate", selector: "StartDate", sortable: true, minWidth: "180px" },
                                { name: "EndDate", selector: "EndDate", sortable: true, minWidth: "180px" },
                                { name: "Status", selector: "status", sortable: true },
                                { name: "Redemption Date", selector: "redemptDate", sortable: true, minWidth: "180px" },
                                { name: "Redeemed By", selector: "redeemedBy", sortable: true },
                                {
                                    name: "Subscription EndDate",
                                    selector: "subscriptionEndDate",
                                    minWidth: "180px",
                                    sortable: true,
                                },
                                { name: "Os", selector: "deviceOs", sortable: true },
                                { name: "isAutoRenwal", selector: "autoRenwal", sortable: true },
                                { name: "Action", selector: "action", sortable: true },
                            ]}
                            data={rowData}
                        />
                    )}
                    <div className={styles.flexEnd}>
                        <TablePagination
                            component="div"
                            count={props.promoCount}
                            page={page}
                            onChangePage={handleChange}
                            rowsPerPage={rowsPerPage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    promos: state.endUser.promos,
    promoCount: state.endUser.promoCount,
});
export default withRouter(connect(mapStateToProps, { getPromoCodes, showAlert })(Promo));
